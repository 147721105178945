/**
 * Created by preference on 2021/03/31
 *  zmx 
 */

import * as API from '@/api/index'
export default {
  /** 9.1.项目品牌列表（不分页）  */
  getProjectBrandList: params => {
    return API.POST('api/projectBrand/all', params)
  },
  /** .9.2.创建项目品牌  */
  createProjectBrand: params => {
    return API.POST('api/projectBrand/create', params)
  },
  /**9.3.更新项目品牌）  */
  updateProjectBrand: params => {
    return API.POST('api/projectBrand/update', params)
  },
  /**9.4.调整项目品牌顺序  */
  moveProjectBrand: params => {
    return API.POST('api/projectBrand/move', params)
  },
}